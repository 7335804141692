form {
    width: 100%;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
    max-width: 500px;
    padding: 50px;
}
body{
    /*background-image: url("../../public/bg.png");*/
    margin-top: -50px;
    background-image: url("../../public/back_blur.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.block_content{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.wrapper{
    margin-top: -50px;
    background-image: url("../../public/back_blur.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: #fff;
    transition: border-color 0.2s ease;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
    border-color: #007bff;
}

textarea {
    resize: vertical;
}

button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

button:hover {
    background-color: #0056b3;
}

/* Optional: Adjust the form layout for smaller screens */
@media (max-width: 600px) {
    form {
        width: 100%;
    }
}

.FinishBlock{
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: 150px;
}

.FinishText{
    color: white;
}

.css-k008qs{
    background: aliceblue !important;
}

.GreenText{
    color: darkolivegreen;
}

.error-message{
    color: firebrick;
}

.wrapdewrap{
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.Price{
    color: whitesmoke;
}

.css-1ulra3u{
    background-color: orange !important;
    color: whitesmoke !important;
    font-weight: bold !important;
}

.box-redirection{
    width: 100%;
    height: 350px;
    margin-top: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.images-map-container{
    width: 100%;
    background-color: firebrick;
    height: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}