.header-navigation{
    display: flex;
    width: 100%;
    height: 52.3px;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    margin-left: -35px;
}

.list-items{
    display: flex;
    padding-right: 0px;
    align-items: flex-start;
    gap: 20.71px;
}

.presentation-section{
    width: 100%;
    height: 1200px;
    background-image: url("../../public/BACK.png");
    background-repeat: no-repeat;
}

.text-right{
    position: absolute;
    width: 30%;
    height: 300px;
    bottom: 0;
    right: 0;
}

.text-presentation{
    color: whitesmoke;
    font-size: 30px;
    font-family: "Microsoft YaHei";
}